<style scoped>
.cms-inner-page{
  padding:70px 0
}
.cms-inner-page .cms-content h2{
  color:#04092c;
  margin:0;
  font-family:'Heebo', sans-serif!important;
  font-weight:600;
  font-size:24px;
  margin-bottom:15px
}
.cms-inner-page .cms-content ul{
  padding-left:30px;
  margin-bottom:20px;
  margin-top:-10px
}
.cms-inner-page .cms-content ul li{
  margin-bottom:10px;
  list-style:disc
}
.cms-inner-page .cms-content ul li ul{
  margin-top:10px
}
.cms-inner-page .cms-content ul li ul li{
  list-style:decimal
}
.cms-inner-page .cms-content li strong,.cms-inner-page .cms-content p,.cms-inner-page .cms-content ul li{
  font-family:'Heebo', sans-serif;
  color:#04092c;
  font-size:15px;
  line-height:24px;
  letter-spacing:0
}
.cms-inner-page .cms-content p.cms-date{
  padding-bottom:15px;
  border-bottom:1px solid #ccc;
  margin-bottom:30px
}
.cms-inner-page .cms-content p strong{
  font-size:17px;
  color:#04092c;
  font-family:'Heebo', sans-serif;
  letter-spacing:0;
  font-weight: 600;
}
.cms-inner-page .cms-content p a,.cms-inner-page .cms-content p a strong,.cms-inner-page .cms-content ul li span a{
  color:#ea3708 !important;
  font-weight:700
}
.innerpage-banner{
  height:150px;
  position: relative;
}
.innerpage-banner:after{
  content:'';
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  display:inline-block;
  background-color:rgb(0 0 0/45%);
  width:100%;
  z-index:2;
}
.innerpage-banner img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.innerpage-banner .section-header{
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex:1;
  flex:1;
  height:100%;
  max-width:100%;
  position:absolute;
  width:100%;
  z-index:3;
  justify-content:center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
}
.innerpage-banner .section-header h2{
  color:#fff;
  font-family:'Heebo', sans-serif !important;
  font-weight:700;
  font-size: 36px;
  text-align: center;
  margin-bottom: 0px;
}
@media (min-width: 1024px){
  .innerpage-banner{
    height:180px
  }
}
@media (max-width: 1280px){
  .innerpage-banner .container{
    max-width:100%
  }
}
@media (max-width: 1024px){
  .cms-inner-page{
    padding:50px 0
  }
  .cms-inner-page .cms-content p,.cms-inner-page .cms-content ul li{
    font-size:14px
  }
  .cms-inner-page .cms-content p strong{
    font-size:15px
  }
  .cms-inner-page .cms-content h2{
    font-size:20px
  }
  .innerpage-banner .section-header h2{
    font-size:32px
  }
}
@media (max-width: 768px) {
  .innerpage-banner .section-header h2{
    font-size:24px
  }
}
@media (max-width: 767px) {
  .innerpage-banner .container{
    max-width:100%
  }
  .cms-inner-page{
    padding:35px 0
  }
}
@media (max-width: 575px){
  .cms-inner-page .cms-content p, .cms-inner-page .cms-content ul li {
    font-size: 13px;
    line-height: 20px;
  }
  .cms-inner-page .cms-content h2{
    font-size:18px
  }
  .cms-inner-page .cms-content p strong{
    font-size:14px
  }
}
</style>
<template>
   <section class="innerpage-banner contact-page">
      <v-lazy-image src="../images/privacy-policy.webp" alt="image" width="1920" height="235"></v-lazy-image>
      <div class="section-header">
         <h2>Terms of Use</h2>
         <BannerSubText />
      </div>
   </section>
   <section class="terms-privacy-wrapper cms-inner-page">
      <div class="container">
         <div class="cms-content">
            <p class="vdp-type-body"><strong>Terms of Use</strong></p>
            <p class="vdp-type-body cms-date"><strong>Effective Date: May 9, 2022</strong></p>
            <p class="vdp-type-body"><strong>THESE SALES TERMS AND CONDITIONS (“SALES TERMS”) CONTAIN AN ARBITRATION AND DISPUTE RESOLUTION PROVISION THAT REQUIRES YOU LIT SEATS, LLC (“LIT SEATS”), PROVIDER, AND ANY OF OUR TICKET SELLERS TO RESOLVE DISPUTES (WITH CERTAIN EXCEPTIONS) BY BINDING AND EXCLUSIVE ARBITRATION INSTEAD OF IN COURT OR BEFORE A JURY, UNLESS YOU CHOOSE TO TIMELY OPT OUT.  IN ARBITRATION, A CLASS, REPRESENTATIVE, OR CONSOLIDATED ACTION OR PROCEEDING WILL NOT BE PERMITTED, AS SET FORTH BELOW.</strong></p>
            <p class="vdp-type-body">Our goal is to make your search and purchasing experience as easy and efficient as possible. These Sales Terms govern your use of LitSeats.com (the “<strong>Website</strong>”) purchases through our customer call center and your purchase of any product from Lit Seats. By using or visiting this Website or purchasing tickets in any manner from the Website, you expressly agree to abide and be bound by these Sales Terms, as well as all applicable laws, ordinances and regulations. You represent that you are legally able to enter into a binding contract. If you are under the age of 18, then you may only use this site or our customer call center in conjunction with, and under the supervision of, a parent or guardian.</p>
            <p class="vdp-type-body">Below you will find the full Sales Terms, which include but are not limited to, the specific items below that we are highlighting for you:</p>
            <ul class="vdp-type-body--list">
               <li class="vdp-type-list-item">All orders placed on the Website or through our customer call center must be confirmed by the respective seller before the Buyer Guarantee takes effect.</li>
               <li class="vdp-type-list-item">Ticket prices may be above "face value" listed on the ticket (they may also be below "face value").</li>
               <li class="vdp-type-list-item">Confirmed orders may be filled with comparable or upgraded tickets if the original tickets are no longer available</li>
               <li class="vdp-type-list-item">If, for reasons of maintaining social distancing or other safety reasons related to the COVID-19 pandemic, any other pandemic, epidemic, or any other health or safety concern, venue security or other personnel relocate you to a location within the venue that is different from the seating location that you had purchased from us, or deny you access to an event for any reason, such relocation or denial of access will not qualify you for a refund or for other compensation.</li>
               <li class="vdp-type-list-item">All sales are final; there are no cancellations, returns, or exchanges.</li>
               <li class="vdp-type-list-item">If an event is cancelled with no rescheduled date, you will receive a full refund of the purchase price (including delivery charges, less possible restocking fees), or a credit for use on a future purchase, as determined at our sole discretion (this may vary by jurisdiction, including California (see California Business and Professions Code section 22507, under which Lit Seats will facilitate a request for a refund from the Provider or a ticket seller). If an event is postponed or rescheduled, and the original tickets are valid for entry at the time of the rescheduled event, your order will not qualify for a refund or other compensation, except in jurisdictions where it is required by law, including California (see California Business and Professions Code section 22507, under which Lit Seats will facilitate the request for a refund from Provider or the ticket seller).</li>
            </ul>
            <h2 class="vdp-type-body">Litseats.com is a Ticket Marketplace, not the Ticket Seller</h2>
            <p class="vdp-type-body">Lit Seats acts as a ticket marketplace. This means that we are an intermediary between ticket buyers and ticket sellers, allowing ticket buyers to find tickets for all their favorite live events. It also means that Lit Seats is not the ticket seller and the listed tickets are not listed by Lit Seats on behalf of any venue, promoter, team, league or organizing group. Lit Seats does not represent any official organizer of the events listed and is not suggesting any endorsement by or association with such official organizers or any venues, events, teams, leagues, performers or attractions. The Lit Seats Website is open to the exchange of any tickets for which there is a market, and that do not violate Lit Seats’ policies.</p>
            <p class="vdp-type-body">The Website licenses a platform that provides inventory. The platform is provided by a service provider (“<strong>Provider</strong>”). Provider also provides additional related services, such as (a) processing your orders; (b) verifying your order details and confirming the validity of your payment information; (c) obtaining payment, such as by charging your credit or debit card; and (d) coordinating the distribution of your tickets. You understand and agree that Provider is solely an independent contractor for the Website. When you use this Website to browse, search for, or buy tickets, you understand and agree that you are utilizing a platform developed and owned by a third party. You understand and agree that Provider is intended to be and constitutes a third-party beneficiary to these Sales Terms.</p>
            <h2 class="vdp-type-body">Ticket Listings</h2>
            <p class="vdp-type-body">Buyers may search  our Website for a specific artist or team, event date or other search category. Each listing contains the event name, date, seat location, and price per ticket. Please note, the ticket price may be higher than the “face value” that appears on the ticket. The “notes” section of the listing, if present, highlights special characteristics of the tickets contained in the listing. Because you will have an opportunity to look at the tickets available for a particular event and determine which tickets to purchase, it is your responsibility to confirm any relevant details by contacting Lit Seats. Ticket Listings do not purport to state the type of ticket and delivery method is not indicative of whether or not a ticket is hard stock or a PDF or any other ticket format. Lit Seats does not guarantee the accuracy of any information provided by sellers on the Lit Seats Marketplace.</p>
            <h2 class="vdp-type-body">Selecting and Purchasing Tickets</h2>
            <p class="vdp-type-body">Once you identify the tickets you would like to purchase, and select them, you will be directed through the checkout process where you will enter your name and address and provide payment information. You will also be able to review the ticket details and the total order price which will include service and delivery fees and any applicable taxes. The service and delivery fees are added to the price of the tickets set by the seller. The service and delivery fees cover the costs of company operations, including connecting buyers to sellers with premium inventory to listed events, premium customer service, website improvements, and safe and secure checkout and delivery of ordered tickets.</p>
            <p class="vdp-type-body">By placing an order, you authorize Provider or another the ticket seller to charge your method of payment for the total order amount. Your order is then sent to the seller for confirmation. The seller will confirm the tickets are still available and you will receive notification that the tickets will be delivered. If you do not receive notification of ticket delivery, contact us at <a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a> for assistance. Upon seller confirmation, your method of payment will be charged.</p>
            <h2 class="vdp-type-body">Ticket Availability</h2>
            <p class="vdp-type-body">All orders are subject to ticket availability. Lit Seats will use commercially reasonable efforts to procure and timely deliver the exact tickets ordered. If those tickets are no longer available, Lit Seats reserves the right to replace tickets with comparable or upgraded tickets, at Lit Seats’ sole, reasonable discretion.</p>
            <h2 class="vdp-type-body">Zone Seating</h2>
            <p class="vdp-type-body">For certain live events, we permit a limited number of pre-approved sellers to offer tickets for sale that they do not currently possess. These tickets are marked on the listing as "Zone Seating." If you purchase zone seating, the seller is committing to obtain the tickets described for you upon receipt of your order. These tickets, like all tickets sold on this site, are backed by our <a class="vdp-type-link" href="javascript:void(0);">100% Buyer Guarantee</a>. After you place your order and your order is confirmed, we guarantee that your tickets will be within the listed zone or section listed or one comparable and that you will receive these tickets in time for the event or your money back. Orders exceeding four tickets may be split up into different rows within the requested zone or section.</p>
            <h2 class="vdp-type-body">Payment</h2>
            <p class="vdp-type-body">By placing your order, you (i) acknowledge that sales are subject to our final sale terms, including the refund, postponement, and cancellation policies as stated throughout these terms; (ii) you acknowledge that Lit Seats does not offer “buyer’s remorse” refunds; and (iii) you authorize the seller to charge your method of payment for the total amount, which includes the ticket price, service and delivery fees and any applicable taxes. You agree that you will not attempt to evade, avoid, or circumvent the limitations on your entitlement to a full or partial refund set forth in these conditions of sale. You will not seek a “chargeback” from a payment card issuer directly on legitimate charges, i.e., where our refusal to refund or credit a ticket purchase conformed to these posted sale terms. In the event that you dispute a charge with the card issuer and it is determined that the charge was valid and not the result of credit card or other payment fraud, Lit Seats or our seller has the right to (i) seek payment, including all associated fees, by whatever lawful means necessary, including using collection agencies and legal proceedings; and (ii) refuse to honor pending and future ticket purchases made from all credit card accounts or online accounts on which such chargebacks have been made, and Lit Seats and our seller may prohibit future purchases by all persons in whose name the credit card accounts exist and any person who accesses any associated online account or credit card or who otherwise breaches this provision. Lit Seats may also mitigate its damages by relisting and selling the tickets that are the subject of the underlying payment dispute.</p>
            <h2 class="vdp-type-body">Pricing Errors</h2>
            <p class="vdp-type-body">A large volume of tickets are listed on the Lit Seats Website, and Lit Seats makes every effort to prevent pricing errors. On rare occasions when pricing errors occur, Lit Seats shall not be liable to the customer for this error. For the avoidance of doubt, if a ticket is subject to a pricing error, Lit Seats may offer the tickets to you at the corrected price. If the corrected price is not acceptable to you, Lit Seats will allow you to cancel your order.</p>
            <h2 class="vdp-type-body">Delivery of Tickets</h2>
            <p class="vdp-type-body">Tickets may be delivered via Instant Download, Email, UPS, or Special Delivery for the charge stated during the checkout process. Some tickets are delivered by an alternative method which will be described at the time of checkout or in a subsequent email. For listings with no designated delivery method, tickets will typically be shipped by UPS. The delivery charge consists of the actual cost of delivery as well as a surcharge that supports Lit Seats’ safe and secure ticket delivery, whether electronic, via shipping, or otherwise. Delivery method is not indicative of the type of ticket. Tickets in all forms, including hard stock and PDF, may be shipped via UPS.</p>
            <p class="vdp-type-body">Delivery will occur on or before the delivery date on the listing, regardless of delivery method. Special Delivery includes same day delivery and may include Will Call, onsite pick up at the venue, pick up from another designated location (typically within two (2) miles of the venue, if possible), or messenger service. The exact delivery location for Special Delivery orders will be designated by the seller.</p>
            <p class="vdp-type-body">When tickets are shipped to you, it is your responsibility to confirm that the tickets delivered to you are consistent with the order you placed. You shall have forty-eight (48) hours from the time of delivery to report any inconsistencies between your order and the delivered tickets. Lit Seats shall not be liable or responsible for any inconsistencies discovered after the 48-hour window has passed.</p>
            <p class="vdp-type-body">Lit Seats reserves the right to change the delivery method, at its sole discretion, and may deliver tickets for any order up to the event start time listed on the tickets. If the delivery method changes at any time prior to delivery, Lit Seats will not charge the buyer additional delivery fees. You must notify Lit Seats if you do not receive your tickets within 48 hours of the actual start time of the scheduled or rescheduled event. If you do not timely notify Lit Seats, you may not be eligible for compensation based on non-delivery of tickets. Photo ID or other verification may be required to accept delivery.</p>
            <p class="vdp-type-body">Tickets are typically delivered per this schedule unless the delivery date in the listing is a date in the future (although timelines can vary in individual circumstances):</p>
            <ul class="vdp-type-body--list">
               <li class="vdp-type-list-item">Instant Download – Within a few minutes of placing the order.</li>
               <li class="vdp-type-list-item">E-tickets – Within a few minutes of placing your order.</li>
               <li class="vdp-type-list-item">Shipped – Arrive in 1-3 business days from placing the order.</li>
               <li class="vdp-type-list-item">Special Delivery – at least one (1) hour before the scheduled event.</li>
            </ul>
            <h2 class="vdp-type-body">Ticket Holder Behavior Policy</h2>
            <p class="vdp-type-body">Attendees to live events must abide by the rules and policies of the venue, promoter and anyone else responsible for the event. Failure to follow such rules and policies may lead to consequences against you or the seller or original ticket holder. If you or another person using the ticket you purchased from Lit Seats fails to abide by those rules and policies, you are responsible for all applicable fines and legal or other expenses associated therewith. If you are asked to leave the event or you miss part of the event due to suspected or confirmed inappropriate behavior, you will not qualify for a refund or any other compensation. Further, should any violation result in the loss of the Provider or ticket seller’s season ticket rights or right to use any other tickets at that venue, or the right to purchase other tickets from that venue, you shall be held liable for all reasonable costs, expenses and losses associated with said loss including, but not limited to, all direct, indirect, vicarious, consequential, exemplary, incidental, special or punitive damages, including lost profits.</p>
            <h2 class="vdp-type-body">All Sales are Final</h2>
            <p class="vdp-type-body"><strong>All sales are final.</strong> As discussed in other provisions of these Sales Terms, no compensation will be given for any reason except as explicitly described in these Sales Terms. This policy is necessary because of our status as a live ticket marketplace. When a purchase is confirmed, the seller removes the associated tickets from the seller’s inventory. The pricing in the market also changes frequently, and just as the seller is not permitted to decline to confirm an order in anticipation of the market going up, buyers are not permitted to return tickets or cancel an order due to the market price going down. A buyer may sell their tickets on the Lit Seats Marketplace if, after purchase, the buyer decides not to attend the event subject to certain restrictions, in which case a buyer may sell the tickets by alternative means.</p>
            <p class="vdp-type-body">If Lit Seats fails to make an on-time delivery of any purchased ticket for an event, then Lit Seats’ sole obligation or liability shall be limited to compensating the buyer for any payments made to Lit Seats under this Agreement. An “on-time delivery” for purposes of this paragraph means delivery prior to the actual start time of the performance, regardless of the performance start time previously posted by any party.  Lit Seats reserves the right to cancel and refund the buyer’s order at any time for any reason.</p>
            <h2 class="vdp-type-body">Cancelled and Postponed Events</h2>
            <p class="vdp-type-body">For cancelled events, Lit Seats will refund the purchase price (including delivery charges, less possible restocking fees), or will issue a credit for use on a future purchase, as determined in its sole discretion (this may vary by jurisdiction, including California (see California Business and Professions Code section 22507, under which Lit Seats will facilitate the request for a refund from Provider or the ticket seller)).   To qualify for compensation, the buyer must return their tickets to Lit Seats within 2 weeks of notice from Lit Seats that the event is deemed “Cancelled.” No refunds or other compensation will be given without the original tickets, unless otherwise determined by Lit Seats, in its sole discretion. Lit Seats will determine when an event is cancelled based upon the best information available. Postponed or rescheduled events will not be refunded or otherwise compensated by Lit Seats except in jurisdictions where it is required by law, including California (see California Business and Professions Code section 22507, under which Lit Seats will facilitate refund requests from the Provider or ticket seller.</p>
            <p class="vdp-type-body">Event date, times, venue and subject matter (collectively “<strong>Event Details</strong>”) may change. We are not always notified if a show is postponed, rescheduled or cancelled. It is the buyer’s responsibility to monitor the event and to confirm any changes to the event with the entity putting on the event. In certain instances, a venue, promoter, or any entity putting on the event will require a ticket holder to relocate his or her seat or otherwise change the seating configuration in a manner beyond Lit Seat's control. Lit Seats shall not be held responsible for any such seating relocations or other changes to the Event Details and will not be obligated to provide a refund or any other compensation.</p>
            <p class="vdp-type-body">If, for reasons of maintaining social distancing or other safety reasons related to the COVID-19 pandemic, any other pandemic or epidemic, or any other health or safety concern, the entity putting on the event has announced a seating or attendee capacity reduction of greater than 15%, Lit Seats, at its sole option, reserves the right to cancel tickets for seating locations eliminated by the venue or to deem such event cancelled in its entirety. In the event that Lit Seats deems an event cancelled in its entirety based on a reduction in capacity: (i) all outstanding orders for such event will be cancelled; and (ii) the event will be reposted for sale with the adjusted capacity and known restrictions. Any buyers whose tickets have been cancelled pursuant to this paragraph shall receive compensation for such cancellation as described above.</p>
            <h2 class="vdp-type-body">Denial of Admission</h2>
            <p class="vdp-type-body">If you have difficulty gaining admission to an event, contact us immediately by calling 866.848.8499. If the issue you are experiencing is not resolved and you are denied admission, you must obtain proof from the venue verifying that you were denied entry and send that to Lit Seats along with a statement describing the specific circumstances. Lit Seats will provide you with a form to complete.</p>
            <p class="vdp-type-body">Upon receipt of the evidence and form, Lit Seats will conduct an investigation. During that investigation, we will review the proof you submit and contact the seller. Other than any denial of entry caused by health or capacity concerns relating to the COVID-19 pandemic, any other pandemic or epidemic, or any other health or safety concern, in the event that Lit Seats determines that you were indeed denied entry, at no fault of your own, you will receive a full refund of the cost of the ticket including all fees and shipping charges, less possible restocking fees, as the sole remedy. If the investigation results in a finding that you either did not attempt to use your tickets, you were granted entry, or your claim that you were denied entry has been found to be false, you will not be refunded and you may be subject to the consequences described below.</p>
            <p class="vdp-type-body">When attending an event, you may be required by the venue or event organizer or such party’s agent to sign a waiver or other legal document in order to gain access to the venue. By purchasing tickets from us, you understand that you may be denied entry if you do not sign such waiver and such denial of entry will not be the responsibility of Lit Seats.</p>
            <p class="vdp-type-body">Your tickets and admission to the event are subject to all venue and/or event organizer/promoter safety and health policies (including, but not limited to, those related to the COVID-19 pandemic), which could include, but are not limited to, health screening or vaccination requirements. You acknowledge that the venue and/or event organizer/promoter may continue to develop and update these policies in the intervening time between your purchase and the event date due to, for example, the uncertainty or evolving nature of the COVID-19 pandemic or other safety or health concerns. By purchasing tickets, you acknowledge and agree that you will comply with such policies and your attendance at the event is conditioned on such compliance. If your admission to the event is denied or revoked because (i) you have willfully failed or refused to comply with any such safety and health policies of the venue and/or event organizer/promoter, or (ii) if you have failed any health screenings or lack the vaccination status required by the venue and/or event organizer/promoter, you will not be eligible for any compensation from Lit Seats.</p>
            <h2 class="vdp-type-body">Warning Related to COVID-19 and Other Diseases or Illnesses</h2>
            <p class="vdp-type-body">COVID-19 is an extremely contagious disease that can lead to severe illness and death.  An inherent risk of exposure to and contraction of COVID-19 exists in any place or venue where people gather.  You assume any and all risks, costs, and harms arising from or relating in any way to the risk of contracting, exposure to, or contraction of a communicable disease or illness—including, without limitation, COVID-19 or any other virus, bacteria, or other pathogen—and you hereby waive any and all claims and potential claims against Lit Seats, and the Indemnified Parties (as such term is defined below) relating to such risks, harms, and/or exposures.</p>
            <h2 class="vdp-type-body">Lost, Stolen, Damaged or Otherwise Inaccessible Tickets</h2>
            <p class="vdp-type-body">Please keep your tickets in a safe place. Lit Seats is not responsible for lost, stolen, damaged, destroyed or otherwise inaccessible tickets and will not provide compensation for your order if you cannot locate or access your tickets once they are delivered to you. As stated above, Lit Seats is not associated with any venue so we do not generate tickets and cannot reissue replacements. Please note that direct sunlight or heat can damage certain types of tickets.</p>
            <p class="vdp-type-body">We may, at your request, ask the seller to re-issue your tickets. If you would like us to submit such a request, the payment method on file will be charged 15% of the order total for the re-issuance of your tickets (maximum of $200). If a re-issue is not possible, you will be refunded the re-issue fee.</p>
            <p class="vdp-type-body">Many tickets are accessible only through display via a mobile device.  It is your responsibility to make certain that your mobile device is charged with sufficient power so that you can access your tickets when you arrive at the venue.</p>
            <h2 class="vdp-type-body">Privacy Policy</h2>
            <p class="vdp-type-body">Lit Seats uses your information only as described in Lit Seats’ Privacy Policy. The Lit Seats’ Privacy Policy is a part of these Sales Terms and is incorporated herein by reference.&nbsp;<a class="vdp-type-link" href="javascript:void(0);" title="View Privacy Policy">View Privacy Policy</a></p>
            <h2 class="vdp-type-body">Unlawful Activity; Acceptable Use</h2>
            <p class="vdp-type-body">Lit Seats prohibits the use of its website for unlawful conduct. All users must comply with all local, state, federal and international laws, ordinances and regulations. By using this site, you agree not to use any false personal information or use an invalid or unauthorized credit or debit card. You agree not to use or permit anyone to use information provided through Litseats.com for any unlawful or unauthorized purpose.</p>
            <p class="vdp-type-body">We are the sole interpreter of this site’s intended and acceptable use. This site is intended to be used by individuals or companies seeking to buy or sell tickets for ultimate use by an individual to attend an event and for no other purpose. If you are a venue, promoter or anyone else responsible for controlling admission to an event, you may not access our site or purchase tickets on this site for purposes of (i) identifying tickets available on our site or the sellers of those tickets; or (ii) revoking or voiding any tickets offered by sellers on this site or otherwise penalizing any sellers for offering tickets on this site.</p>
            <h2 class="vdp-type-body">Investigations and Consequences</h2>
            <p class="vdp-type-body">Lit Seats monitors compliance with these Sales Terms and investigates alleged violations and other complaints against users. This includes any attempt to defraud or otherwise harm Lit Seats or our sellers. You are required to cooperate in such investigations including, but not limited to, providing any and all information requested.</p>
            <p class="vdp-type-body">Following an investigation, or for any other reason, Lit Seats may, without prior notice, enforce certain consequences for violations of the Sales Terms, attempts to harm Lit Seats and/or sellers, or failure to cooperate with an investigation. Such consequences include but are not limited to:</p>
            <ul class="vdp-type-body--list">
               <li class="vdp-type-list-item">Canceling confirmed orders</li>
               <li class="vdp-type-list-item">Issuing a warning</li>
               <li class="vdp-type-list-item">Suspending or terminating your account</li>
               <li class="vdp-type-list-item">Denying access to the Lit Seats Website</li>
               <li class="vdp-type-list-item">Rejecting pending orders</li>
               <li class="vdp-type-list-item">Blocking future transactions</li>
               <li class="vdp-type-list-item">Taking legal action such as filing civil or criminal charges or seeking an injunction.</li>
            </ul>
            <p class="vdp-type-body">You agree that monetary damages may not provide a sufficient remedy to Lit Seats for violations of these Sales Terms and that the damages and harm caused by such violation may lead to impaired good will, lost sales and increased expenses that are difficult to calculate. Accordingly, you consent to injunctive or other equitable relief for such violations.</p>
            <p class="vdp-type-body">Lit Seats reserves the right to report to appropriate law enforcement authorities or other relevant third parties any activity that it believes, in its sole discretion, may in any way violate any local, state, federal or international law.</p>
            <h2 class="vdp-type-body">Intellectual Property Ownership</h2>
            <p class="vdp-type-body">The site, including all site software, databases, trademarks, logos, service marks, proprietary information and materials (and any intellectual property and other rights relating thereto) ("<strong>Lit Seats Property</strong>") is owned by Lit Seats and will remain the property of Lit Seats. Users of this site acknowledge that they do not acquire any ownership rights by using the site. Users may not use any Lit Seats Property in connection with any product or service that is not offered by Lit Seats, in any manner that is likely to cause confusion with Lit Seats' business, or in any manner that disparages Lit Seats. Nothing contained on the site should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Lit Seats Property without the express written permission of Lit Seats.</p>
            <p class="vdp-type-body">The content, organization, graphics, design, compilation, "look and feel" and all Lit Seats Property available on this site, including, without limitation, images and written and other materials (the "<strong>Contents</strong>"), are intellectual property protected under the copyright, trademark and other intellectual property laws of the United States and/or other countries ("<strong>Intellectual Property Laws</strong>"). You may not download, print, copy, reproduce, distribute, transmit, broadcast, display, sell, license or otherwise use or exploit any of the Content except in the course of viewing the site online for lawful purposes, and in making single copies of selected pages of the site for personal use and not for distribution or posting on any other site. You also agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on any Content. The violation of applicable Intellectual Property Laws may give rise to civil and/or criminal penalties. No right, title or interest in any downloaded materials is transferred to you as a result of any such downloading or copying other than the foregoing license to possess for personal use.</p>
            <p class="vdp-type-body">Lit Seats respects the intellectual property of others, and we ask you to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: (a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (b) a description of the copyrighted work that you claim has been infringed; (c) a description of where the material that you claim is infringing is located on the site; (d) your address, telephone number and email address; (e) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (f) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf. Our Copyright Agent for notice of claims of copyright infringement can be reached at: Attn: <a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>.</p>
            <h2 class="vdp-type-body">Indemnification</h2>
            <p class="vdp-type-body">You agree to indemnify, defend and hold Lit Seats, its parents, affiliates, investors, licensors, suppliers, advertisers and sponsors, as well Provider and any ticket sellers, and their respective employees, consultants, agents and other representatives ("<strong>Indemnified Parties</strong>") harmless from and against any and all claims, damages, losses, costs (including reasonable attorneys' fees) and other expenses that arise directly or indirectly out of or from: (a) your breach of any of these Sales Terms; (b) any allegation that any information you submit or transmit to the site infringe or otherwise violates the copyright, trademark, trade secret or other intellectual property or other rights of any third party; (c) any federal, state, or county tax obligation or amounts due or owing under any tax regulation, law, order or decree or any dispute concerning the tax status of Lit Seats; and/or (d) your activities in connection with your use of this site.</p>
            <h2 class="vdp-type-body">Disclaimers and Limitations on Liability</h2>
            <h2 class="vdp-type-body">No Warranty</h2>
            <p class="vdp-type-body">THE WEBSITE, THE MATERIALS ON THE SITE AND ANY TICKET OR SERVICE OBTAINED THROUGH THE SITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. LIT SEATS DISCLAIMS, TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE SITE, THE MATERIALS, AND ANY TICKETS OR SERVICE OBTAINED THROUGH THE SITE, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING OR USAGE OF TRADE. LIT SEATS DOES NOT WARRANT THAT YOUR USE OF THE SITE WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITE AND YOUR RELIANCE THEREON. LIT SEATS IS NOT RESPONSIBLE IN ANY WAY FOR THE ACCURACY OR SUITABILITY OF ANY PAYMENT OF TAXES TO ANY ENTITY ON YOUR BEHALF. YOUR USE OF THE SITE AND ANY MATERIALS PROVIDED THROUGH THE SITE ARE ENTIRELY AT YOUR OWN RISK. YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
            <p class="vdp-type-body">IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE § 1542, WHICH STATES: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.</p>
            <h2 class="vdp-type-body">Limitation of Liability</h2>
            <p class="vdp-type-body">NEITHER LIT SEATS NOR ANY OTHER INDEMNIFIED PARTY ARE OR WILL BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY TYPE (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS OR LOST PROFITS) AS WELL FOR AS ANY MULTIPLIER ON OR INCREASE TO DAMAGES, UNDER ANY CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, STATUTORY, COMMON LAW, EQUITABLE, OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, THE MATERIALS ON THE SITE OR ANY TICKET OR SERVICE OBTAINED THROUGH THE SITE (INCLUDING ATTENDANCE AT ANY EVENT). WITHOUT LIMITING THE FOREGOING, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT LIT SEATS AND ANY OTHER INDEMNIFIED PARTY SHALL HAVE NO LIABILITY OR RESPONSIBILITY WHATSOEVER FOR: (I) ANY ACTION OF ANOTHER USER ON THE SITE; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER ARISING IN CONTRACT OR IN TORT, RESULTING FROM YOUR ACCESS TO OR USE OF THE SITE, OR ATTENDANCE AT AN EVENT, INCLUDING ANY CLAIM, CAUSE OF ACTION, OBLIGATION, LIABILITY, RIGHT, OR REMEDY WHETHER OR NOT ARISING FROM THE NEGLIGENCE OF LIT SEATS; (III) ANY UNAUTHORIZED ACCESS; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE; (IV) ANY BUGS, VIRUSES, WORMS, DEFECTS OR OTHER ITEMS OF A DESTRUCTIVE NATURE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY; (V) ANY ERROR, MISTAKE, INACCURACY OR OMISSION IN ANY MATERIALS, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY MATERIALS AVAILABLE THROUGH THE SITE; AND/OR (VI) ANY LOST, STOLEN OR DAMAGED TICKETS.</p>
            <p class="vdp-type-body">THE MAXIMUM LIABILITY OF LIT SEATS, AND ANY OTHER INDEMNIFIED PARTY, AND YOUR SOLE AND EXCLUSIVE REMEDY, FOR ALL DAMAGES, LOSSES SUFFERED BY YOU AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE, SHALL BE THE GREATER OF (A) THE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS THE SITE OVER THE PAST TWELVE (12) MONTHS; OR (B) $200.</p>
            <p class="vdp-type-body">YOU ACKNOWLEDGE AND AGREE THAT NEITHER LIT SEATS NOR ANY OF THE INDEMNIFIED PARTIES ARE LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD LIT SEATS AND THE INDEMNIFIED PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING BUT NOT LIMITED TO PROVIDER, TICKET SELLERS, OPERATORS OF VENUES AND EVENT ORGANIZERS/PROMOTERS, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES, SITES AND LOCATIONS RESTS ENTIRELY WITH YOU.</p>
            <p class="vdp-type-body">SOME JURISDICTIONS, INCLUDING BUT NOT LIMITED TO THE STATE OF NEW JERSEY,  DO NOT ALLOW CERTAIN AGREEMENTS TO INCLUDE THE PROVISIONS OF THIS SECTION THAT (A) LIMIT OR EXCLUDE CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY TYPE; (B) LIMIT OR EXCLUDE THE USE OF ANY MULTIPLIER ON OR INCREASE TO DAMAGES; AND (C) LIMIT THE LIABILITY OF LIT SEATS, AND ANY OTHER INDEMNIFIED PARTY, TO THE GREATER OF (1) THE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS THE SITE OVER THE PAST TWELVE (12) MONTHS; OR (2) $200.  THESE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.  THE PROVISIONS OF THIS SECTION DO NOT APPLY TO THE EXTENT, AND ONLY TO THE EXTENT, NOT PERMITTED BY APPLICABLE LAW.</p>
            <h2 class="vdp-type-body">Arbitration and Dispute Resolution</h2>
            <p class="vdp-type-body"><strong>You and Lit Seats each agree that, except as provided in the Exceptions provision below, any and all disputes, controversies, or claims arising out of or relating in any way to: (i) these Sales Terms; (ii) your use of, or access to, the site; (iii) Lit Seats’ services; (iv) any tickets or other items sold or purchased through the website; or (iv) any marketing, advertising, statements, or representations regarding or related in any way to these Sales Terms, the site (including access to and use thereof), Lit Seats’ services, and any tickets or other items sold or purchased through the site shall be fully, finally, and exclusively resolved through final and binding arbitration rather than in court.  YOU AND LIT SEATS EACH WAIVE ANY RIGHT TO A JURY TRIAL AS WELL AS ANY RIGHT TO BRING CLAIMS IN OR OTHERWISE PARTICIPATE IN A CLASS, REPRESENTATIVE, OR CONSOLIDATED ACTION OR PROCEEDING, AS SET FORTH BELOW.</strong></p>
            <p class="vdp-type-body"><strong>Exceptions: </strong>Notwithstanding the remainder of this Arbitration and Dispute Resolution section, You and Lit Seats agree that the following types of disputes will be resolved in court, unless both You and Lit Seats agree to submit the dispute to arbitration pursuant to this Arbitration and Dispute Resolution section: </p>
            <p class="vdp-type-body">(1) Disputes or claims within the jurisdiction of a small claims court; or</p>
            <p class="vdp-type-body">(2) Disputes or claims where the sole form of relief sought is injunctive relief (including public injunctive relief).</p>
            <p class="vdp-type-body">For the avoidance of doubt, the waiver of the right to bring claims in or otherwise participate in a class, representative, or consolidated action or proceeding set forth in the Class Action Waiver provision below does not prevent You from seeking public injunctive relief in an individual capacity to the extent otherwise permitted by law.</p>
            <p class="vdp-type-body">You and Lit Seats also agree that for disputes or claims where <em>both</em> injunctive relief (including public injunctive relief) and non-injunctive relief are sought, You and Lit Seats will first submit the dispute or claim for non-injunctive relief to arbitration pursuant to this Arbitration and Dispute Resolution section.  The arbitrator will not be permitted to grant injunctive relief (unless the parties mutually agree otherwise).  Once the arbitration of the dispute or claim for non-injunctive relief has concluded, You and/or Lit Seats may seek the injunctive relief (including the public injunctive relief) in court to the extent permitted by law.</p>
            <p class="vdp-type-body">Any litigation in court of the foregoing types of disputes (except for small claims court actions) may be commenced only in a federal or state court located within Cook County, Illinois, and You and Lit Seats each consent to the jurisdiction of those courts for such purposes.  Regardless of whether the foregoing types of disputes in this Exceptions provision are resolved by a court or pursuant to arbitration, you and Lit Seats agree that the dispute is subject to the Class Action Waiver provision set forth below.</p>
            <p class="vdp-type-body"><strong>Delegation: </strong>The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute arising out of or relating to the formation, existence, scope, validity, interpretation, applicability, or enforceability of this agreement to arbitrate, or any part of it, or of these Sales Terms, including, but not limited to, any claim that all or any part of this agreement to arbitrate or the Sales Terms is void or voidable.  If any party disagrees about whether the foregoing provision (or any portion of this agreement to arbitrate, including without limitation the provisions relating to arbitration, the waiver of class or representative proceedings, and opting out) can be enforced or whether it applies to the dispute, the parties all agree that the arbitrator will decide that dispute.</p>
            <p class="vdp-type-body"><strong>Governing Law:</strong> The Federal Arbitration Act (“FAA”) governs this agreement to arbitrate in all respects.  This means that the FAA governs, among other things, the interpretation and enforcement of the parties’ arbitration agreement and all of its provisions, including, without limitation, the Class Action Waiver.  State arbitration laws do not govern in any respect.  Further, you and Lit Seats each agree that the Sales Terms evidence a transaction involving interstate commerce and will be governed by and construed in accordance with federal law to the fullest extent possible.  To the extent state law applies, the parties’ arbitration agreement and the parties’ Class Action Waiver shall be governed by Illinois law, subject to conflict of law principles.</p>
            <p class="vdp-type-body"><strong>Informal Initial Dispute Mechanism: </strong>If either you or Lit Seats intends to bring a claim against the other, that party must first send to the other, by certified mail, a completed Intent to Arbitrate Notice ("Intent Notice"). The Intent Notice to Lit Seats should be sent to Lit Seats, LLC, Attention: Legal Department, 246 Monmouth Road, Oakhurst, NJ 07755. If you and Lit Seats are unable to resolve the dispute described in the Intent Notice within thirty (30) days after the Intent Notice is sent, you or Lit Seats may initiate an arbitration proceeding.</p>
            <p class="vdp-type-body"><strong>Initiating Arbitration Proceedings; Rules, Venue, and Jurisdiction: </strong>The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with its Consumer Arbitration Rules, as modified by this agreement to arbitrate. Exclusive jurisdiction for an arbitration hearing required under the AAA Consumer Arbitration Rules, if any, shall be in Cook County, Illinois. The AAA’s rules are available at www.adr.org. A form for initiating arbitration proceedings, Demand for Arbitration, is available on the AAA website. In addition to filing the Demand for Arbitration with the AAA, in accordance with its rules and procedures, you must send a copy of the completed form to Lit Seats at the following address: Lit Seats LLC, Attention: Support@litseats.com.  The arbitrator will decide the substance of all claims in accordance with the laws of the State of Illinois, subject to conflict of law principles. The arbitrator’s award shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.</p>
            <p class="vdp-type-body">In the event that the AAA is unable to conduct the arbitration for any reason, you and Lit Seats will mutually select an alternative arbitration provider, and the arbitration will be conducted pursuant to that provider’s applicable rules.</p>
            <p class="vdp-type-body">If the agreement to arbitrate is held unenforceable or invalid for any reason, any litigation against Lit Seats (except for small claims court actions, as discussed above) may be commenced only in a federal or state court located within Cook County, Illinois, and you and we each consent to the jurisdiction of those courts for such purposes.</p>
            <p class="vdp-type-body"><strong>Selection of Arbitrator: </strong>The parties agree that there will be one arbitrator and that the process for appointing an arbitrator will be the following manner: The AAA shall send simultaneously to each party to the dispute an identical list of 10 names of persons chosen from the National Roster (unless the AAA decides that a different number is appropriate).  For a dispute or claim relating to the intellectual property of you, Lit Seats, or any of Lit Seats’ corporate family members (for example, trademarks, trade dress, domain names, trade secrets, copyrights, or patents), the list from the National Roster shall be limited to individuals who either previously served as a federal judge or is an attorney who has specialized in intellectual property law for at least ten (10) years.</p>
            <p class="vdp-type-body">The parties will confer to attempt to agree to an arbitrator from the submitted list and to advise the AAA of their agreement. If the parties are unable to agree upon an arbitrator, each party to the dispute shall have 14 calendar days from the transmittal date in which to strike names objected to, number the remaining names in order of preference, and return the list to the AAA. The parties are not required to exchange selection lists. If a party does not return the list within the time specified, all persons named therein shall be deemed acceptable to that party. From among the persons who have been approved on both lists, and in accordance with the designated order of mutual preference, the AAA shall invite the acceptance of an arbitrator to serve. If the parties fail to agree on any of the persons named, or if acceptable arbitrators are unable to act, or if for any other reason the appointment cannot be made from the submitted lists, the AAA shall have the power to make the appointment from among other members of the National Roster without the submission of additional lists, except that for the appointment of an arbitrator for a dispute or claim relating to the intellectual property of you, Lit Seats, or any of Lit Seats’ corporate family members (for example, trademarks, trade dress, domain names, trade secrets, copyrights, or patents), the individual appointed shall either previously have served as a federal judge or be an attorney who has specialized in intellectual property law for at least ten (10) years.</p>
            <p class="vdp-type-body"><strong>Arbitration Fees and Costs; Attorneys’ Fees and Expenses: </strong>Payment of all filing, administration, and arbitrator fees will be governed by the AAA’s rules, unless otherwise stated in this agreement to arbitrate. If the value of the relief sought is $10,000 or less, at your request, Lit Seats will pay all filing, administrative and arbitrator fees associated with the arbitration. Any request for payment of fees by Lit Seats should be submitted by mail to the AAA along with the Demand for Arbitration, and Lit Seats will make arrangements to pay all necessary fees directly to the AAA.</p>
            <p class="vdp-type-body">Each party is responsible for his, her, or its own attorneys’ fees and expenses, and Lit Seats will not pay your attorneys’ fees or expenses except to the extent ordered to do so by the arbitrator.  If you prevail in arbitration, however, you will be entitled to an award of reasonable attorneys’ fees and expenses to the extent allowed for under applicable law and ordered by the arbitrator.  In the event the arbitrator determines the claim you asserted in the arbitration to be frivolous according to Federal Rule of Civil Procedure 11, or brought for an improper purpose, you agree to reimburse Lit Seats for all fees associated with the arbitration paid by Lit Seats that you otherwise would have been obligated to pay under the AAA rules.  If the arbitrator, upon final disposition of the case, finds your dispute was not frivolous, Lit Seats will reimburse any filing fees that you paid and were not otherwise reimbursed.</p>
            <p class="vdp-type-body"><strong>Opting Out:</strong> You can choose to reject this agreement to arbitrate. If you do not wish to be bound by this agreement to arbitrate and/or the Class Action Waiver, you must notify Lit Seats in writing within thirty (30) days of the date that you first access the site. Your written notification must include your name and address, as well as a clear statement that you do not wish to resolve disputes with Lit Seats through arbitration. Written notification should be mailed by certified mail to: Lit Seats, LLC, Attention: Litigation Department, 246 Monmouth Road, Oakhurst, NJ 07755. If you do not opt out within thirty (30) days, then you accept all terms and conditions of the arbitration and dispute resolution procedures described above.</p>
            <p class="vdp-type-body"><strong>Class Action Waiver: </strong><strong>To the fullest extent permitted by applicable law, you and Lit Seats each agree that any proceeding to resolve any dispute, claim, or controversy will be brought and conducted ONLY IN THE RESPECTIVE PARTY’S INDIVIDUAL CAPACITY AND NOT AS PART OF ANY CLASS (OR PURPORTED CLASS), CONSOLIDATED, MULTIPLE-PLAINTIFF, OR REPRESENTATIVE ACTION OR PROCEEDING (“Class Action”). You and Lit Seats AGREE TO WAIVE THE RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS ACTION. You and Lit Seats EXPRESSLY WAIVE ANY ABILITY TO MAINTAIN A CLASS ACTION IN ANY FORUM.  If the dispute is subject to arbitration, THE ARBITRATOR WILL NOT HAVE AUTHORITY TO COMBINE OR AGGREGATE CLAIMS, CONDUCT A CLASS ACTION, OR MAKE AN AWARD TO ANY PERSON OR ENTITY NOT A PARTY TO THE ARBITRATION. Further, you and Lit Seats agree that THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OR MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CLASS ACTION. For the avoidance of doubt, however, You can seek public injunctive relief to the extent authorized by law, warranted by an individual claim(s), and consistent with the Exceptions clause above.</strong></p>
            <p class="vdp-type-body"><strong>IF THIS CLASS ACTION WAIVER IS LIMITED, VOIDED, OR FOUND UNENFORCEABLE, THEN, UNLESS THE PARTIES MUTUALLY AGREE OTHERWISE, THE PARTIES’ AGREEMENT TO ARBITRATE SHALL BE NULL AND VOID WITH RESPECT TO SUCH PROCEEDING SO LONG AS THE PROCEEDING IS PERMITTED TO PROCEED AS A CLASS ACTION. </strong></p>
            <p class="vdp-type-body"><strong>Confidentiality: </strong>Unless applicable law provides otherwise, the arbitration proceeding and all records pertaining to it—including but not limited to any documents prepared or produced in connection with the arbitration proceeding, as well as the hearing and the arbitration award—will be confidential and will not be disclosed to any third party, except as necessary to obtain court confirmation of the arbitration award.</p>
            <p class="vdp-type-body"><strong>Resale Tickets to Events in Illinois:</strong> Solely for purposes of 815 ILCS 414/1.5(c), for transactions involving resale tickets to events in Illinois, You or the ticket reseller may elect to submit complaints against one another to the AAA under its rules and procedures. The AAA’s rules are available at www.adr.org. Such complaints shall be decided by an independent arbitrator in accordance with these Sales Terms. You and ticket resellers also agree to submit to the jurisdiction of the State of Illinois for complaints involving a ticketed event held in Illinois.</p>
            <h2 class="vdp-type-body">Modification</h2>
            <p class="vdp-type-body">Lit Seats has the right, in its sole discretion, to modify, suspend or discontinue any part of this site at any time, with or without notice.</p>
            <h2 class="vdp-type-body">Changes in Terms and Conditions</h2>
            <p class="vdp-type-body">Lit Seats reserves the right, in its sole discretion, to change these Sales Terms at any time. If Lit Seats changes any term or condition, said modification, revision and additional information shall be posted here, automatically replace the existing terms and conditions, become effective immediately, and become binding on all users of this site. Your continued use of the site following Lit Seats' posting of revised terms and conditions constitutes your acceptance of the revised agreement.</p>
            <h2 class="vdp-type-body">Force Majeure</h2>
            <p class="vdp-type-body">Lit Seats shall not be deemed in default or otherwise liable under these Sales Terms due to its inability to perform its obligations by reason of any act of God, fire, earthquake, blizzard, flood, epidemic, pandemic, danger to public health or safety, accident, explosion, casualty, strike, lockout, labor controversy, riot, civil disturbance, act of public enemy, embargo, war, law, ordinance, regulation, legal order (unless caused by Lit Seats’ default hereunder), failure or delay of any transportation, power, or communications system or any other similar cause not under Lit Seats' control.</p>
            <h2 class="vdp-type-body">Allocation of Risk</h2>
            <p class="vdp-type-body">You acknowledge and agree that the foregoing disclaimers and limitations of liability represent bargained for allocations of risk and that the pricing and other terms and conditions of this agreement reflect such allocation of risk.</p>
            <h2 class="vdp-type-body">Additional Provisions</h2>
            <p class="vdp-type-body">No agency, partnership, joint venture or other relationship is intended or created by your use of the site.</p>
            <p class="vdp-type-body">These Sales Terms, the use of the Litseats.com and any purchases of tickets hereunder will be governed by the laws of the State of Illinois, subject to conflict of law principles.</p>
            <p class="vdp-type-body">These Sale Terms contain the entire understanding of the parties with respect to the matters contained herein and supersedes and replaces in its entirety any and all prior communications and contemporaneous agreements and understandings between the parties, whether oral, written, electronic or implied.</p>
            <p class="vdp-type-body">If any provision of these Sales Terms is held invalid or unenforceable under any circumstance, its application in any other circumstances and the remaining provisions shall not be affected. Further, the provision that has been deemed to be invalid or ineffective shall be enforced to the greatest extent permitted by law.</p>
            <p class="vdp-type-body">The heading at the beginning of each paragraph is for reference purposes and in no way defines the scope or extent of such paragraph.</p>
         </div>
      </div>
   </section>
</template>

<script>
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'

export default {
  name: 'Terms',
  components: {
    VLazyImage,
    BannerSubText
  },
  data: function () {
    return {
      appName: process.env.VUE_APP_NAME,
      phoneNumberTollFree: process.env.VUE_APP_CONTACT_US_TOLL_FREE_NUMBER,
      phoneNumberInternational: process.env.VUE_APP_CONTACT_US_INTERNATIONAL_NUMBER,
      contactEmail: process.env.VUE_APP_CONTACT_US_EMAIL
    }
  },
  mounted () {
    document.title = 'Terms Of Use | ' + process.env.VUE_APP_NAME
  }
}
</script>
